import {
    IS_DEV,
    IS_PROD,
    IS_NON_PROD,
    MY_REFRESH_COOKIE,
    ACCESS_TOKEN_COOKIE,
    REFRESH_TOKEN_COOKIE,
    FOOD_TOUR_KEY,
    ACHIEVED_WEIGHT_COOKIE,
    HF_RETAKE_ALERT_ID,
    MEMBER_ID,
    HF_RETAKE_REACHOUT_NOT_NEEDED_KEY,
    HF_RETAKE_LINK_TIMED_OUT_KEY,
    MEMBER_COMMUNICATION_POLICY,
    MEMBER_SUPPORT_EMAIL,
    ONEAPP_SUPPORT_EMAIL,
    MEMBER_SUPPORT_URL,
    ZENDESK_URL,
    TWILIO_SMS_PHONE_NUMBER,
    REGISTER_LINK,
    IOS_APP_LINK,
    ANDROID_APP_LINK,
    IOS_ONEAPP_LINK,
    ANDROID_ONEAPP_LINK,
    TERMS_OF_SERVICE_URL,
    PRIVACY_POLICY_URL,
    PRIVACY_PRACTICES_URL,
    COPA_PRIVACY_URL,
    NAME_INPUT_PATTERN,
    ADDRESS_INPUT_PATTERN,
    EMAIL_INPUT_MAX_LENGTH,
    EMAIL_INPUT_PATTERN,
    CONFETTI_COLORS,
    PHONE_INPUT_PATTERN,
    FORMAT_DATETIME_NO_TZ,
    REFERRER_REGEX,
    BANNER_LAUNCH_DATE,
    BANNER_EXPIRY_DATE,
    NON_DISCRIMINATION_URL,
    CONDITION_MANAGEMENT_HOMEPATH,
    MEMBER_SUPPORT_PHONE_NUMBER,
    DESKTOP_SCREEN_SIZE,
    MOBILE_SCREEN_SIZE,
    FEATURE_FLAG_NAMES,
    EDUCATION_TOKEN_COOKIE,
    ONEAPP_PROFILE_SETTINGS_PATH,
    EDUCATION_SOURCE_PARAM,
    FOOD_INSIGHTS_PATH,
    STAGES_OF_KIDNEY_DISEASE_LINK,
    UNDERSTANDING_KIDNEY_RESULTS_LINK,
    INTRO_DPP_VIDEO_URL,
    INTRO_DM_VIDEO_URL,
    INTRO_HTN_VIDEO_URL,
    ONBOARDING_INTRO_SEEN,
    TEAMS_APP_ID_URL,
    sessionUUID,
} from '@teladoc/fe-ccm/ui/config';

export default {
    IS_DEV,
    IS_PROD,
    IS_NON_PROD,
    MY_REFRESH_COOKIE,
    ACCESS_TOKEN_COOKIE,
    REFRESH_TOKEN_COOKIE,
    FOOD_TOUR_KEY,
    ACHIEVED_WEIGHT_COOKIE,
    HF_RETAKE_ALERT_ID,
    MEMBER_ID,
    HF_RETAKE_REACHOUT_NOT_NEEDED_KEY,
    HF_RETAKE_LINK_TIMED_OUT_KEY,
    MEMBER_COMMUNICATION_POLICY,
    MEMBER_SUPPORT_EMAIL,
    ONEAPP_SUPPORT_EMAIL,
    MEMBER_SUPPORT_URL,
    ZENDESK_URL,
    TWILIO_SMS_PHONE_NUMBER,
    REGISTER_LINK,
    IOS_APP_LINK,
    ANDROID_APP_LINK,
    IOS_ONEAPP_LINK,
    ANDROID_ONEAPP_LINK,
    TERMS_OF_SERVICE_URL,
    PRIVACY_POLICY_URL,
    PRIVACY_PRACTICES_URL,
    COPA_PRIVACY_URL,
    NAME_INPUT_PATTERN,
    ADDRESS_INPUT_PATTERN,
    EMAIL_INPUT_MAX_LENGTH,
    EMAIL_INPUT_PATTERN,
    CONFETTI_COLORS,
    PHONE_INPUT_PATTERN,
    FORMAT_DATETIME_NO_TZ,
    REFERRER_REGEX,
    BANNER_LAUNCH_DATE,
    BANNER_EXPIRY_DATE,
    NON_DISCRIMINATION_URL,
    CONDITION_MANAGEMENT_HOMEPATH,
    MEMBER_SUPPORT_PHONE_NUMBER,
    DESKTOP_SCREEN_SIZE,
    MOBILE_SCREEN_SIZE,
    FEATURE_FLAG_NAMES,
    EDUCATION_TOKEN_COOKIE,
    ONEAPP_PROFILE_SETTINGS_PATH,
    EDUCATION_SOURCE_PARAM,
    FOOD_INSIGHTS_PATH,
    STAGES_OF_KIDNEY_DISEASE_LINK,
    UNDERSTANDING_KIDNEY_RESULTS_LINK,
    INTRO_DPP_VIDEO_URL,
    INTRO_DM_VIDEO_URL,
    INTRO_HTN_VIDEO_URL,
    ONBOARDING_INTRO_SEEN,
    TEAMS_APP_ID_URL,
    sessionUUID,
};
