import {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import Button from '@teladoc/pulse/ui/Button';
import Form from '@teladoc/pulse/ui/Form';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import TextArea from '@teladoc/pulse/ui/TextArea';
import notify from '@teladoc/pulse/ui/Notification';
import isEmpty from 'lodash/isEmpty';
import useMounted from '@livongo/utilities/hooks/useMounted';
import StorageUtils from '@livongo/utilities/system/storage';
import ApptentiveUtils from '../../../common/apptentive/apptentive-utils';
import MixpanelUtils from '../../../common/utilities/mix-panel';
import ChatAPI from '../../chat-api';
import ChatUtils from '../../chat-utils';
import LaunchpadAPI from '../../../launchpad/launchpad-api';
import CommonUtils from '../../../common/utilities/common-utils';
import {appUpdate} from '../../../app/app-actions';
import {SEND_MESSAGE_ONCE} from '../../../config';
import css from './MessageEntry.scss';

const onSendSuccess = () => {
    const event = 'chat.chatscreen.send.success';

    MixpanelUtils.track({event});
    ApptentiveUtils.event(event);
};

const MessageEntry = ({
    channel,
    selectedTalkToNewCoach,
    isFirstInteraction,
    onFirstInteractionComplete,
    onMessageSent,
}) => {
    const formRef = useRef();
    const {t} = useTranslation();
    const {isMounted} = useMounted();
    const storageKey = `${channel.name}-entry`;
    const [formKey, setFormKey] = useState(Date.now());
    const {isOneApp} = useSelector(state => state.app);
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useDispatch();
    const onChange = evt => {
        // report to a coach in Salesforce that the user is actively typing
        channel.startTyping();

        StorageUtils.set({
            key: storageKey,
            value: evt.target.value,
            type: 'session',
        });
    };
    const onSendClick = async evt => {
        const form = formRef.current;

        if (!form.isValid()) {
            return;
        }

        const {message: untrimmedMsg} = form.submit();
        const message = untrimmedMsg.trim();

        // don't allow a message with only a space
        if (!message.length) {
            return;
        }

        channel.endTyping();
        setIsSaving(true);

        if (isOneApp) {
            if (isEmpty(CommonUtils.getLocalStorage(SEND_MESSAGE_ONCE))) {
                let source;
                const pathname = document.location.pathname;

                if (pathname.includes('message-center/chronic_care')) {
                    source = 'message_center';
                } else if (pathname.includes('condition-management/coaching')) {
                    source = 'coaching center';
                } else if (pathname.includes('condition-management/home')) {
                    source = 'onboarding';
                }

                MixpanelUtils.track({
                    event: 'coaching.message.send.tapped',
                    properties: {
                        button: 'Send Message',
                        source,
                    },
                });
                CommonUtils.setLocalStorage({
                    key: SEND_MESSAGE_ONCE,
                    value: 'true',
                });
            }
        }

        try {
            if (isFirstInteraction) {
                // create one on one chat first to create SendBird credentials and send message along with it
                const data = await ChatAPI.createUser(message);
                const credentials = data.getAuth();

                await ChatAPI.connect({
                    userId: credentials.getUserId(),
                    token: credentials.getSessionToken(),
                });

                if (channel.url === ChatUtils.NEW_CHANNEL_URL) {
                    // get the bare newly created channel data based on the ID returned by the Livongo API
                    const newChannel = await ChatAPI.getChannelById(
                        data.getChannelId()
                    );

                    // use the above to grab the full channel data (including last message) for this new channel from Sendbird
                    const {channel: newChannelData} =
                        await ChatAPI.loadMessages({
                            channel: newChannel,
                        });

                    onFirstInteractionComplete(newChannelData);
                } else {
                    // If a channel exists and it's their first time interacting
                    // we still want the parent `Messages` to set
                    // isFirstInteraction to false so the
                    // right status is displayed
                    onFirstInteractionComplete();
                }

                if (isMounted) {
                    // mark first-time connect with a coach for Launchpad
                    LaunchpadAPI.patchStatus({
                        item: {name: 'COACHING', completed: true},
                    });

                    dispatch(
                        appUpdate({
                            hasConnectedToCoach: true,
                        })
                    );
                }

                onSendSuccess();
            } else {
                // user already exists, go ahead and send the message to the channel
                const newMessage = await ChatAPI.sendMessage({
                    channel,
                    message,
                });
                const {customType: channelType} = channel;

                if (channelType === ChatUtils.CHANNEL_TYPES.FOOD) {
                    const {isAvailable} = ChatUtils.getCoachAvailability({
                        channel,
                        isAwaitingCoach: false,
                    });

                    // if a coach isn't available, request a new coach behind the scenes so that they can be claimed by another coach
                    if (!isAvailable) {
                        ChatAPI.requestCoach({
                            channelType,
                            lastMessage: message,
                        });
                    }
                } else {
                    const coachesInChannel = ChatUtils.getUsersInChannel({
                        channel,
                        type: ChatUtils.USER_TYPES.COACH,
                    });

                    // if there are no coaches in the channel, try to request the prior coach again
                    if (!coachesInChannel.length) {
                        // handle case where user selected to talk to a new coach (to avoid messaging both previous and new coach)
                        ChatAPI.requestCoach({
                            channelType,
                            lastMessage: message,
                            requestPreviousCoach: !selectedTalkToNewCoach,
                        });
                    }
                }

                onSendSuccess();

                onMessageSent(newMessage);
            }

            StorageUtils.remove({key: storageKey, type: 'session'});

            if (isMounted) {
                setIsSaving(false);
                setFormKey(Date.now());
            }
        } catch (error) {
            MixpanelUtils.track({
                event: 'chat.chatscreen.send.failed',
            });

            if (isMounted) {
                setIsSaving(false);

                notify({
                    type: 'critical',
                    title: t('header.chat.notifications.sendError.title'),
                    message: t('header.chat.notifications.sendError.message'),
                });
            }
        }
    };

    useEffect(() => {
        return () => {
            channel.endTyping();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form key={formKey} ref={formRef} className={css.root} id="addMessage">
            <TextArea
                id="message"
                name="message"
                classNameRoot={css.input}
                classNameItem={css.inputItem}
                label={<Label required>{t('header.chat.input')}</Label>}
                maxLength={5000}
                minRows={1}
                maxRows={5}
                error={
                    <FormElementError>{t('forms.required')}</FormElementError>
                }
                required
                defaultValue={StorageUtils.get({
                    key: storageKey,
                    type: 'session',
                })}
                onChange={onChange}
            />
            <div className={css.actions}>
                <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    loading={isSaving}
                    disabled={isSaving}
                    onClick={onSendClick}
                >
                    {t('header.chat.send')}
                </Button>
            </div>
        </Form>
    );
};

MessageEntry.propTypes = {
    channel: PropTypes.object.isRequired,
    selectedTalkToNewCoach: PropTypes.bool.isRequired,
    onFirstInteractionComplete: PropTypes.func.isRequired,
    isFirstInteraction: PropTypes.bool.isRequired,
    onMessageSent: PropTypes.func.isRequired,
};

export default MessageEntry;
